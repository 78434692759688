import React, { Fragment, useState, useEffect } from "react";
import "./Product.css";

export default function Product({
  producto,
  AgregarProductosState,
  productsBranded,
  productsWithoutBrand,
  productsSPA,
}) {
  const { name, price, quantity } = producto;

  const [cargando, spinnerCargando] = useState(false);

  useEffect(() => {
    if (Object.keys(producto).length !== 0) {
      spinnerCargando(true);
      setTimeout(() => {
        spinnerCargando(false);
      }, 3000);
    }
  }, [producto]);

  const priceProduct = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(price);

  let productoYaAgregado = false;

  if (
    producto.type === 1 &&
    Array.isArray(productsWithoutBrand) &&
    productsWithoutBrand.some((p) => p.product_id === producto.id)
  ) {
    productoYaAgregado = true;
  }
  if (
    producto.type === 2 &&
    Array.isArray(productsBranded) &&
    productsBranded.some((p) => p.product_id === producto.id)
  ) {
    productoYaAgregado = true;
  }
  if (
    producto.type === 3 &&
    Array.isArray(productsSPA) &&
    productsSPA.some((p) => p.product_id === producto.id)
  ) {
    productoYaAgregado = true;
  }

  return (
    <Fragment>
      {quantity && !productoYaAgregado ? (
        <>
          <button className="boton-agregar" onClick={AgregarProductosState}>
            Agregar
          </button>
        </>
      ) : null}
    </Fragment>
  );
}
