import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Typography from "@material-ui/core/Typography";
import { Button, Tooltip } from "@material-ui/core";
import Icon from "@material-ui/icons/PictureAsPdf";
import GridOnIcon from "@material-ui/icons/GridOn";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Moment from "moment";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import FormControl from "@material-ui/core/FormControl";

/**Import Sweet Alert2 */
import Swal from "sweetalert2";
import Alert from "@material-ui/lab/Alert";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(18),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  SpacingGrid: {
    marginTop: "4%",
    marginLeft: "1rem",
  },
  button: {
    marginTop: "2%",
  },
  buttonBranch: {
    marginTop: "5%",
    marginLeft: "7rem",
  },
  paperTitle: {
    background: "indigo",
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
}));

export default function UtilitySales() {
  const classes = useStyles();

  const [fechaInicio, GuardarFechaInicio] = useState("");
  const [fechaFin, GuardarFechaFin] = useState("");
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());
  const [error, guardarError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [nameOffice, guardarSucursal] = useState({
    id_branch_office: "",
  });

  const cambiarFechaInicio = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaInicio(fecha);
    handleDateChangeStart(date);
  };
  const cambiarFechaFin = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaFin(fecha);
    handleDateChangeEnd(date);
  };

  const detectarCambiosOffice = (e) => {
    guardarSucursal({ id_branch_office: e.target.value });
  };
  /*
    const GenerarReporte = e => {
        e.preventDefault();

        const data = {
            'start_date': fechaInicio,
            'end_date': fechaFin
        }
        clienteAxios.post('/reporte-utilidad-general', data, { responseType: 'blob' }).then(response => {
            fileDownload(response.data, 'Reporte Utilidad General ' + fechaInicio + ' - ' + fechaFin + '.pdf');
            guardarError(false);
        }).catch(error => {
            if (error.response.status === 409) {
                Swal.fire({
                    icon: 'error',
                    title: 'Algo sucedio',
                    text: 'No se cuenta con suficiente información para generar el reporte'
                })
            } else if (error.response.status === 422) {
                guardarError(true)
            }
        }); 
    }
*/
  const handleError = (error) => {
    let errorMessage = "Ocurrió un error inesperado.";

    if (error.response) {
      const { status, data } = error.response;

      if (data.error) {
        errorMessage = data.error;
      }

      switch (status) {
        case 422:
          errorMessage =
            data.error?.end_date?.[0] ||
            "La fecha de fin no puede ser anterior a la fecha de inicio.";
          break;
        case 409:
          errorMessage =
            data.error ||
            "No se cuentan con suficientes ventas para generar el reporte.";
          break;
        default:
          errorMessage = "Ocurrió un error inesperado";
          break;
      }
    }

    Swal.fire({
      icon: "warning",
      title: "Error al generar el reporte",
      text: errorMessage,
    });
  };

  const GenerarReporteSucursal = async (e, formato = "pdf") => {
    e.preventDefault();

    if (!fechaInicio || !fechaFin || !nameOffice.id_branch_office) {
      return Swal.fire({
        icon: "warning",
        title: "Faltan datos",
        text: "Por favor, selecciona las fechas de inicio, fin y sucursal.",
      });
    }

    if (new Date(fechaInicio) > new Date(fechaFin)) {
      return Swal.fire({
        icon: "warning",
        title: "Fechas incorrectas",
        text: "La fecha de fin no puede ser anterior a la fecha de inicio.",
      });
    }

    Swal.fire({
      icon: "info",
      title: "Generando reporte...",
      text: "Por favor, espera un momento.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = {
        start_date: fechaInicio,
        end_date: fechaFin,
        branch_office_id: nameOffice.id_branch_office,
      };

      const response = await clienteAxios.post(
        `/reporte-utilidad-sucursal?format=${formato}`,
        data,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const fileExtension = formato === "excel" ? "xlsx" : "pdf";
        fileDownload(
          response.data,
          `Reporte_Corte_de_Caja_Sucursales_${fechaInicio}_${fechaFin}.${fileExtension}`
        );

        Swal.fire({
          icon: "success",
          title: "Reporte generado",
          text: `El archivo ${fileExtension.toUpperCase()} se ha descargado correctamente.`,
        });
      } else {
        throw new Error("No se pudo generar el reporte.");
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}

      <Grid container spacing={2}>
        {error ? (
          <Alert severity="warning">Todos los campos son obligatorios!</Alert>
        ) : null}
        <form
          onSubmit={GenerarReporteSucursal}
          className={classes.form}
          noValidate
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Fecha Inicio"
                  disableFuture
                  value={selectedDateStart}
                  onChange={cambiarFechaInicio}
                  InputAdornmentProps={{ position: "start" }}
                />
                {!fechaInicio ? (
                  <p style={{ color: "red" }}>
                    La Fecha Inicial aún no ha sido seleccionada
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  disableFuture
                  label="Fecha Fin"
                  value={selectedDateEnd}
                  onChange={cambiarFechaFin}
                  InputAdornmentProps={{ position: "start" }}
                />
                {!fechaFin ? (
                  <p style={{ color: "red" }}>
                    La Fecha Final aún no ha sido seleccionada
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={4} style={{ paddingTop: 16 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <SelectBranchOffice
                    detectarCambiosOffice={detectarCambiosOffice}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  onClick={(e) => GenerarReporteSucursal(e, "pdf")}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "red", color: "white" }}
                  className={classes.button}
                  endIcon={<Icon>send</Icon>}
                >
                  Generar Reporte PDF
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  onClick={(e) => GenerarReporteSucursal(e, "excel")}
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "green", color: "white" }}
                  className={classes.button}
                  endIcon={<GridOnIcon />}
                >
                  Generar Reporte EXCEL
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      </Grid>
    </>
  );
}
