import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Moment from "moment";
import Swal from "sweetalert2";
import clienteAxios from "../../Config/Axios";
import fileDownload from "js-file-download";
import { Button, FormControl, Grid, Icon, Tooltip } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import SelectUser from "../SelectOptions/SelectUser";
import { Alert } from "@material-ui/lab";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ReportOutputs() {
  const classes = useStyles();
  const [fechaInicio, GuardarFechaInicio] = useState("");
  const [fechaFin, GuardarFechaFin] = useState("");
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());
  const [error, guardarError] = useState(false);

  const [id_branch2, saveBranch2] = useState(null);
  const detectarCambiosBranch2 = (e) => {
    saveBranch2(e.target.value);
  };

  const [id_branch, saveBranch] = useState(null);
  const detectarCambiosBranch = (e) => {
    saveBranch(e.target.value);
  };
  const cambiarFechaInicio = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaInicio(fecha);
    handleDateChangeStart(date);
  };
  const cambiarFechaFin = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaFin(fecha);
    handleDateChangeEnd(date);
  };

  const handleError = (error) => {
    let errorMessage = "Ocurrió un error inesperado.";

    if (error.response) {
      const { status, data } = error.response;

      if (data.error) {
        errorMessage = data.error;
      }

      switch (status) {
        case 422:
          errorMessage =
            data.error?.end_date?.[0] ||
            "La fecha de fin no puede ser anterior a la fecha de inicio.";
          break;
        case 404:
          errorMessage =
            data.error ||
            "No se encontraron registros de salidas de productos en este rango de fechas.";
          break;
        case 409:
          errorMessage =
            data.error ||
            "No hay información disponible para generar el reporte.";
          break;
        default:
          errorMessage = "Ocurrió un error inesperado";
          break;
      }
    }

    Swal.fire({
      icon: "warning",
      title: "Error al generar el reporte",
      text: errorMessage,
    });
  };

  const GenerarReporteUsuario = async (e) => {
    e.preventDefault();

    if (!fechaInicio || !fechaFin || !id_branch || !id_branch2) {
      return Swal.fire({
        icon: "warning",
        title: "Faltan datos",
        text: "Por favor, selecciona las fechas de inicio, fin y sucursales.",
      });
    }

    if (new Date(fechaInicio) > new Date(fechaFin)) {
      return Swal.fire({
        icon: "warning",
        title: "Fechas incorrectas",
        text: "La fecha de fin no puede ser anterior a la fecha de inicio.",
      });
    }

    Swal.fire({
      icon: "info",
      title: "Generando reporte...",
      text: "Por favor, espera un momento.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = {
        start_date: fechaInicio,
        end_date: fechaFin,
        branch_office_id: id_branch,
        branchout: id_branch2,
      };

      const response = await clienteAxios.post(
        "/reporte-salidas-sucursal",
        data,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        fileDownload(
          response.data,
          `Reporte Entrada De Productos ${fechaInicio} - ${fechaFin}.pdf`
        );

        Swal.fire({
          icon: "success",
          title: "Reporte generado",
          text: "El archivo se ha descargado correctamente.",
        });
      } else {
        throw new Error("No se pudo generar el reporte.");
      }
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading} fontWeight="bold">
            Traspaso a otra sucursal
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {error ? (
              <Alert severity="warning">
                Todos los campos son obligatorios!
              </Alert>
            ) : null}
            <form
              onSubmit={GenerarReporteUsuario}
              className={classes.form}
              noValidate
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Fecha Inicio"
                      disableFuture
                      value={selectedDateStart}
                      onChange={cambiarFechaInicio}
                      InputAdornmentProps={{ position: "start" }}
                    />
                    {!fechaInicio ? (
                      <p style={{ color: "red" }}>
                        La Fecha Inicial aún no ha sido seleccionada
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      disableFuture
                      label="Fecha Fin"
                      value={selectedDateEnd}
                      onChange={cambiarFechaFin}
                      InputAdornmentProps={{ position: "start" }}
                    />
                    {!fechaFin ? (
                      <p style={{ color: "red" }}>
                        La Fecha Final aún no ha sido seleccionada
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 16 }}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.formControl}
                    >
                      <SelectBranchOffice
                        detectarCambiosOffice={detectarCambiosBranch}
                      />
                    </FormControl>
                  </Grid>
                  {id_branch && (
                    <Grid item xs={12} sm={6} style={{ paddingTop: 16 }}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                      >
                        <SelectBranchOffice
                          detectarCambiosOffice={detectarCambiosBranch2}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <Tooltip
                      title="Generar Reporte"
                      aria-label="Generar Reporte"
                    >
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<Icon>send</Icon>}
                      >
                        Generar Reporte
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </form>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
