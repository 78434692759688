import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Icon from "@material-ui/icons/PictureAsPdf";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Moment from "moment";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import FormControl from "@material-ui/core/FormControl";
import GridOnIcon from "@material-ui/icons/GridOn";

/**Import Sweet Alert2 */
import Swal from "sweetalert2";
import Alert from "@material-ui/lab/Alert";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import { useState } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ProductsEntry() {
  const classes = useStyles();
  const [fechaInicio, GuardarFechaInicio] = useState("");
  const [fechaFin, GuardarFechaFin] = useState("");
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());
  const [error, guardarError] = useState(false);
  const [nameOffice, guardarSucursal] = useState({
    id_branch_office: "",
  });
  const cambiarFechaInicio = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaInicio(fecha);
    handleDateChangeStart(date);
  };
  const cambiarFechaFin = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaFin(fecha);
    handleDateChangeEnd(date);
  };

  const detectarCambiosOffice = (e) => {
    guardarSucursal({ id_branch_office: e.target.value });
  };

  const GenerarReporte = async (e, formato = "pdf") => {
    e.preventDefault();

    if (!fechaInicio || !fechaFin) {
      return Swal.fire({
        icon: "warning",
        title: "Faltan datos",
        text: "Por favor, selecciona las fechas de inicio y fin.",
      });
    }

    if (new Date(fechaInicio) > new Date(fechaFin)) {
      return Swal.fire({
        icon: "warning",
        title: "Fechas incorrectas",
        text: "La fecha de fin no puede ser anterior a la fecha de inicio.",
      });
    }

    Swal.fire({
      icon: "info",
      title: "Generando reporte...",
      text: "Por favor, espera un momento.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = {
        start_date: fechaInicio,
        end_date: fechaFin,
      };

      const response = await clienteAxios.post(
        `/reporte-entrada-general?format=${formato}`,
        data,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const fileExtension = formato === "excel" ? "xlsx" : "pdf";
        fileDownload(
          response.data,
          `Reporte_Entrada_De_Productos_General_${fechaInicio}_${fechaFin}.${fileExtension}`
        );

        Swal.fire({
          icon: "success",
          title: "Reporte generado",
          text: `El archivo ${fileExtension.toUpperCase()} se ha descargado correctamente.`,
        });
      } else {
        throw new Error("No se pudo generar el reporte.");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    let errorMessage = "Ocurrió un error inesperado.";

    if (error.response) {
      const { status, data } = error.response;

      if (data.error) {
        errorMessage = data.error;
      }

      switch (status) {
        case 422:
          errorMessage =
            data.error?.end_date?.[0] ||
            "La fecha de fin no puede ser anterior a la fecha de inicio.";
          break;
        case 404:
          errorMessage =
            data.error ||
            "No se encontraron productos para el rango de fechas seleccionado.";
          break;
        case 409:
          errorMessage =
            data.error ||
            "No hay información disponible para generar el reporte.";
          break;
        default:
          errorMessage = "Ocurrió un error inesperado";
          break;
      }
    }

    Swal.fire({
      icon: "warning",
      title: "Error al generar el reporte",
      text: errorMessage,
    });
  };

  const GenerarReporteSucursal = async (e, formato = "pdf") => {
    e.preventDefault();

    if (!fechaInicio || !fechaFin || !nameOffice.id_branch_office) {
      return Swal.fire({
        icon: "warning",
        title: "Faltan datos",
        text: "Por favor, selecciona las fechas de inicio, fin y sucursal.",
      });
    }

    if (new Date(fechaInicio) > new Date(fechaFin)) {
      return Swal.fire({
        icon: "warning",
        title: "Fechas incorrectas",
        text: "La fecha de fin no puede ser anterior a la fecha de inicio.",
      });
    }

    Swal.fire({
      icon: "info",
      title: "Generando reporte...",
      text: "Por favor, espera un momento.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = {
        start_date: fechaInicio,
        end_date: fechaFin,
        branch_office_id: nameOffice.id_branch_office,
      };

      const response = await clienteAxios.post(
        `/reporte-entrada-sucursal?format=${formato}`,
        data,
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const fileExtension = formato === "excel" ? "xlsx" : "pdf";
        fileDownload(
          response.data,
          `Reporte_Entrada_De_Productos_Sucursal_${fechaInicio}_${fechaFin}.${fileExtension}`
        );

        Swal.fire({
          icon: "success",
          title: "Reporte generado",
          text: `El archivo ${fileExtension.toUpperCase()} se ha descargado correctamente.`,
        });
      } else {
        throw new Error("No se pudo generar el reporte.");
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} fontWeight="bold">
            Entrada de productos General
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form onSubmit={GenerarReporte} noValidate>
              <Grid
                container
                spacing={2}
                sx={{ padding: 2 }}
                style={{ width: "100%" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    inputVariant="outlined"
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Fecha Inicio"
                    disableFuture
                    value={selectedDateStart}
                    onChange={cambiarFechaInicio}
                    InputAdornmentProps={{ position: "start" }}
                  />
                  {!fechaInicio ? (
                    <p style={{ color: "red" }}>
                      La Fecha Inicial aún no ha sido seleccionada
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    inputVariant="outlined"
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    disableFuture
                    label="Fecha Fin"
                    value={selectedDateEnd}
                    onChange={cambiarFechaFin}
                    InputAdornmentProps={{ position: "start" }}
                  />
                  {!fechaFin ? (
                    <p style={{ color: "red" }}>
                      La Fecha Final aún no ha sido seleccionada
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    onClick={(e) => GenerarReporte(e, "pdf")}
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "red", color: "white" }}
                    className={classes.button}
                    endIcon={<Icon>send</Icon>}
                  >
                    Generar Reporte PDF
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    onClick={(e) => GenerarReporte(e, "excel")}
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "green", color: "white" }}
                    className={classes.button}
                    endIcon={<GridOnIcon />}
                  >
                    Generar Reporte EXCEL
                  </Button>
                </Grid>
              </Grid>
            </form>
          </MuiPickersUtilsProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading} fontWeight="bold">
            Entrada de productos sucursal
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {error ? (
              <Alert severity="warning">
                Todos los campos son obligatorios!
              </Alert>
            ) : null}
            <form
              onSubmit={GenerarReporteSucursal}
              className={classes.form}
              noValidate
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Fecha Inicio"
                      disableFuture
                      value={selectedDateStart}
                      onChange={cambiarFechaInicio}
                      InputAdornmentProps={{ position: "start" }}
                    />
                    {!fechaInicio ? (
                      <p style={{ color: "red" }}>
                        La Fecha Inicial aún no ha sido seleccionada
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      disableFuture
                      label="Fecha Fin"
                      value={selectedDateEnd}
                      onChange={cambiarFechaFin}
                      InputAdornmentProps={{ position: "start" }}
                    />
                    {!fechaFin ? (
                      <p style={{ color: "red" }}>
                        La Fecha Final aún no ha sido seleccionada
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.formControl}
                    >
                      <SelectBranchOffice
                        detectarCambiosOffice={detectarCambiosOffice}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      onClick={(e) => GenerarReporteSucursal(e, "pdf")}
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: "red", color: "white" }}
                      className={classes.button}
                      endIcon={<Icon>send</Icon>}
                    >
                      Generar Reporte PDF
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      onClick={(e) => GenerarReporteSucursal(e, "excel")}
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: "green", color: "white" }}
                      className={classes.button}
                      endIcon={<GridOnIcon />}
                    >
                      Generar Reporte EXCEL
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </form>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
