import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import ModalAdd from "../Branch_Offices/ModalAdd";
import {
  Tooltip,
  Fab,
  Typography,
  Grid,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import LocalPrintshopRoundedIcon from "@material-ui/icons/LocalPrintshopRounded";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import AddIcon from "@material-ui/icons/Add";
import MethodGet, { MethodDelete } from "../../Config/Services";
import Swal from "sweetalert2";
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  Table: {
    background: "Orange",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  reportmodal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportpaper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: "80%",
    height: "60%",
    padding: theme.spacing(2, 1, 1, 3),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const OfficeStocksSubAdmin = (props) => {
  const classes = useStyles();

  //sucursales = state, saveStocks =funcion para guardar en el state;
  const [stocks, saveStocks] = useState([]);
  const id = props.id; //Se toma el ID de la Sucursal para mostrar sus stocks
  const [cargando, spinnerCargando] = useState(false);

  //abrirModal
  const [modalAdd, abrirModalAdd] = useState(false);
  const [stockid, editarStockId] = useState("");

  const [branch, guardarBranch] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = branch;

  const handleClickOpenAdd = (id) => {
    editarStockId(id);
    abrirModalAdd(true);
  };

  const handleCloseAdd = () => {
    abrirModalAdd(false);
  };

  const [actualizar, actualizarStock] = useState(false);

  const actualizarCambios = () => {
    editarStockId("");
    actualizarStock(true);
  };

  //query a la api
  useEffect(() => {
    let url = `/sucursalstocks/${id}`;
    MethodGet(url)
      .then((res) => {
        saveStocks(res.data.data);
        spinnerCargando(true);
        actualizarStock(false);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [actualizar]);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  const deleteStock = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "El Stock del Producto Seleccionado no se Prodrá Recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        // eliminar en la rest api
        let url = `/stocks/${id}`;
        MethodDelete(url)
          .then((res) => {
            if (res.status === 200) {
              const CambioIndexStocks = stocks.filter(
                (stock) => stock.id !== id
              );
              saveStocks(CambioIndexStocks);
              Swal.fire(
                "Stock de Producto Eliminado",
                res.data.mensaje,
                "success"
              );
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error,
              icon: "error",
            });
          });
      }
    });
  };

  {
    /** empieza Cambio para Reporte individual por sucursal */
  }
  const [error, guardarError] = useState(false);
  const GenerarReporteOffice = (e) => {
    e.preventDefault();
    let timerInterval;
    Swal.fire({
      title: "Generando",
      html: "El reporte se está generando",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
    clienteAxios
      .get(`/reporte-individual/${id}`, { responseType: "blob" })
      .then((response) => {
        fileDownload(response.data, "Reporte Stock Productos por Sucursal.pdf");
        guardarError(false);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Algo sucedio",
            text: "No se cuenta con suficiente información para generar el reporte",
          });
        } else if (error.response.status === 422) {
          guardarError(true);
        }
      });
  };
  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            className={classes.typography}
            component='h1'
            variant='h4'
          >
            {"Stocks de la Sucursal " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align='right'
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            onClick={GenerarReporteOffice}
            className={classes.fabGreen}
            aria-label='add'
            size='medium'
          >
            <Tooltip
              title='Generar mi Reporte de Stock'
              aria-label='Generar mi Reporte de Stock'
            >
              <LocalPrintshopRoundedIcon type='submit' size='large' />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=''
            columns={[
              { title: "Clave", field: "clave" },
              { title: "Producto", field: "products_name" },
              {
                title: "Cantidad existente",
                field: "quantity",
                render: (rowData) => {
                  return (
                    <p className={classes.Link} style={{ color: "blue" }}>
                      {rowData.quantity}
                    </p>
                  );
                },
              },
              {
                title: "Estatus",
                field: "quantity",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.quantity == 0 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align='center'
                        >
                          Productos Agotados
                        </p>
                      ) : rowData.quantity <= 200 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#ffc400",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align='center'
                        >
                          Productos Por Agotarse
                        </p>
                      ) : rowData.quantity >= 201 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align='center'
                        >
                          Productos Suficientes En Existencia
                        </p>
                      ) : null}
                    </Fragment>
                  );
                },
              },
            ]}
            data={stocks}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "add",
                tooltip: "Agregar Existencias",
                onClick: () => handleClickOpenAdd(rowData.id),
              }),
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=''
            columns={[
              { title: "Clave", field: "clave" },
              { title: "Producto", field: "products_name" },
            ]}
            data={stocks}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>
                        Cantidad Existente: <>{rowData.quantity}</>{" "}
                      </p>
                      <p>
                        Estatus:{" "}
                        {
                          <Fragment>
                            {rowData.quantity == 0 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "red",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align='center'
                              >
                                Productos Agotados
                              </p>
                            ) : rowData.quantity <= 200 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#ffc400",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align='center'
                              >
                                Productos Por Agotarse
                              </p>
                            ) : rowData.quantity >= 201 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#43a047",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align='center'
                              >
                                Productos Suficientes En Existencia
                              </p>
                            ) : null}
                          </Fragment>
                        }
                      </p>
                      <p>Opciones: </p>

                      <p>
                        {" "}
                        {
                          <>
                            <IconButton
                              variant='contained'
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 50,
                              }}
                              size='small'
                              onClick={() => handleClickOpenAdd(rowData.id)}
                            >
                              <Tooltip
                                title='Agregar Existencias'
                                aria-label='Agregar Existencias'
                              >
                                <AddIcon style={{ color: "gray" }} />
                              </Tooltip>
                            </IconButton>
                          </>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <ModalAdd
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleCloseAdd}
        stockid={stockid}
        actualizarCambios={actualizarCambios}
      />
    </LayoutDashboard>
  );
};

export default OfficeStocksSubAdmin;
