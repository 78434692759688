import React, { useState, useEffect } from "react";
import Product from "./Product";
import { Grid } from "@material-ui/core";
import MethodGet from "../../Config/Services";
import Swal from "sweetalert2";

const ProductInfo = ({
  productoID,
  productsBranded,
  productsWithoutBrand,
  productsSPA,
  saveProductsSPA,
  saveProductsWithoutBrand,
  saveProductsBranded,
  guardarProductoID,
  clienteID,
  setValue,
  typeProduct,
}) => {
  const [cargandoAnimacion, guardarAnimacion] = useState(false);
  const [producto, guardarProducto] = useState({});
  const [cantidad, guardarCantidad] = useState(1);
  const [errorCantidad, guardarError] = useState(false);

  useEffect(() => {
    guardarAnimacion(true);
    setTimeout(() => {
      guardarAnimacion(false);
    }, 3000);
  }, []);
  //console.log(productoID,'info')
  let url = `/products/${productoID}`;
  useEffect(() => {
    if (productoID) {
      MethodGet(url)
        .then((response) => {
          guardarProducto(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [productoID]);

  const AgregarProductosState = () => {
    let productoYaAgregado = false;

    if (
      producto.type === 1 &&
      Array.isArray(productsWithoutBrand) &&
      productsWithoutBrand.some((p) => p.product_id === producto.id)
    ) {
      productoYaAgregado = true;
    }
    if (
      producto.type === 2 &&
      Array.isArray(productsBranded) &&
      productsBranded.some((p) => p.product_id === producto.id)
    ) {
      productoYaAgregado = true;
    }
    if (
      producto.type === 3 &&
      Array.isArray(productsSPA) &&
      productsSPA.some((p) => p.product_id === producto.id)
    ) {
      productoYaAgregado = true;
    }

    if (productoYaAgregado) {
      return; 
    }

    if (cantidad > producto.quantity) {
      guardarError(true);
      return;
    } else if (clienteID == undefined) {
      Swal.fire({
        title: "Antes de continuar",
        text: "Selecciona un cliente",
        icon: "error",
        timer: 2500,
        showConfirmButton: false,
      });
      return;
    }

    guardarError(false);
    let productoResultado = producto;
    //generar una llave unica para cada producto
    productoResultado.product_id = producto.id;
    //generar cantidad productos por adquirir
    productoResultado.cantidad = Number(cantidad);
    productoResultado.type = Number(producto.type);
    //generar precio final por producto
    productoResultado.final_price = Number(cantidad) * Number(producto.price);
    //guardarlo en el state
    //console.log(producto);
    if (productoResultado.type === 1) {
      saveProductsWithoutBrand([productoResultado, ...productsWithoutBrand]);
      setValue(1);
    }
    if (productoResultado.type === 2) {
      saveProductsBranded([productoResultado, ...productsBranded]);
      setValue(0);
    }
    if (productoResultado.type === 3) {
      saveProductsSPA([productoResultado, ...productsSPA]);
      setValue(2);
    }
    // console.log(productsWithoutBrand,'productsWithoutBrand')
    // console.log(productsBranded,'productsBranded')
    // console.log(productoResultado,'productoResultado')
    //resetear el state del producto
    guardarProductoID("");
    guardarProducto({});
    guardarCantidad(1);
    localStorage.setItem(
      "EnterProducts",
      JSON.stringify([productoResultado, ...productsBranded])
    );
  };

  return (
    <Grid style={{ marginTop: "-19px", paddingLeft: "15px" }}>
      {typeProduct === 2 && (
        <Product
          producto={producto}
          AgregarProductosState={AgregarProductosState}
          guardarCantidad={guardarCantidad}
          errorCantidad={errorCantidad}
        />
      )}
    </Grid>
  );
};

export default ProductInfo;
