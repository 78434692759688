import React, { useContext, useEffect, useState } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import AuthContext from "../../Context/autenticacion/authContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import WapizimaNewLogo from "../../Complements/Image/Dashboard/WapizimaNewLogo.png";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Box,
  Fab,
  Typography,
  Paper,
  Container,
  ButtonBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import '../Principal/style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingInlineEnd: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  welcomeView: {
    height: "auto",
    //background: "linear-gradient(#ff5252,#f48fb1)",
    backgroundImage: `url("https://source.unsplash.com/category/nature/daily")`,
    position: "relative",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 11px 15px -9px rgba(0,0,0,0.63)",
    linearGradient: "rgba(0, 0, 0, 0.9)",
    WebkitAnimation: "AnimationName 5s ease infinite",
    animation: "AnimationName 5s ease infinite",
  },
  imageWapizima: {
    paddingTop: theme.spacing(5),
    padding: theme.spacing(5),
    fontSize: "5vw",
    maxWidth: "100%",
    height: "auto",
    aling: "center",
    color: "white",
  },
  containerButtons: {
    paddingTop: theme.spacing(4.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  textWelcome: {
    fontSize: "4vw",
    color: "white",
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.5,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.05,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
    color: "white",
    fontSize: 27,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const Home = (props) => {
  const classes = useStyles();
  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuarioAutenticado, usuario } = authContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  const images = [
    {
      url: "https://img.freepik.com/foto-gratis/vendedor-femenino-muestra-cosmeticos-tienda-maquillaje-mujer-tienda-belleza-maquillaje_266732-5760.jpg",
      //url: 'https://image.freepik.com/foto-gratis/grupo-esmaltes-color-rosa-sobre-fondo-azul_106006-277.jpg',
      title: "VENTAS",
      width: "25%",
      href: "/Ventas",
    },
    {
      url: "https://img.freepik.com/foto-gratis/mujer-toma-lapiz-labial-estante-tienda-cosmeticos-comprador-escaparate-salon-belleza-lujo-clienta-mercado-moda_266732-22889.jpg",
      //url: 'https://laopinion.com/wp-content/uploads/sites/3/2020/02/81jpn-jvctl._sl1500_-e1581903722858.jpg',
      title: "PRODUCTOS",
      width: "25%",
      href: "/Productos",
    },
    {
      url: "https://img.freepik.com/foto-gratis/disenadora-concentrada-usando-tableta-grafica-computadora_171337-15942.jpg",
      title: "INSCRIPCIONES",
      width: "25%",
      href: "/Inscripciones",
    },
    {
      url: "https://s1.1zoom.me/big7/725/Oleg_Gekman_Hair_Makeup_Glance_Face_Hairstyle_565530_1920x1080.jpg",
      //url: 'https://d1eipm3vz40hy0.cloudfront.net/images/SSAC-Blog/cual-es-la-importancia-del-servicio-al-cliente.jpg',
      title: "CLIENTES",
      width: "25%",
      href: "/Clientes",
    },
  ];

  const [saludo, setSaludo] = useState("");
  useEffect(() => {
    const obtenerSaludo = () => {
      const horaActual = new Date().getHours();

      if (horaActual >= 6 && horaActual < 12) {
        setSaludo("Buenos Dias");
      } else if (horaActual >= 12 && horaActual < 18) {
        setSaludo("Buenas Tardes");
      } else {
        setSaludo("Buenas Noches");
      }
    };
    obtenerSaludo();
  }, []);

  return (
    <LayoutDashboard>
      <>
        <div className={classes.root}>
          <CssBaseline />
          <Container className={classes.container}>
            <Grid>
              <Paper className={classes.welcomeView}>
                <Grid
                  container
                  xs={12}
                  xl={12}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{
                    marginTop: "40px",
                    background: "rgb(63,81,181)",
                    background:
                      "linear-gradient(90deg, rgba(63,81,181,1) 0%, rgba(72,21,143,1) 100%)",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    xl={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="h3"
                      component="h4"
                      className={classes.imageWapizima}
                    >
                      Hola, {saludo}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    xl={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{
                      backdropFilter: " blur(2px)",
                    }}
                  >
                    <Typography
                      variant="h2"
                      component="h6"
                      className={classes.textWelcome}
                    >
                      {usuario && usuario.name}.
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    xl={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid className={classes.containerButtons}>
                      {images.map((image) => (
                        <ButtonBase
                          href={image.href}
                          focusRipple
                          key={image.title}
                          className={classes.image}
                          focusVisibleClassName={classes.focusVisible}
                          style={{
                            width: image.width,
                          }}
                        >
                          <span
                            className={classes.imageSrc}
                            style={{
                              backgroundImage: `url(${image.url})`,
                            }}
                          />
                          <span className={classes.imageBackdrop} />
                          <span className={classes.imageButton}>
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="inherit"
                              className={classes.imageTitle}
                            >
                              {image.title}
                              <span className={classes.imageMarked} />
                            </Typography>
                          </span>
                        </ButtonBase>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Container>
        </div>
      </>
    </LayoutDashboard>
  );
};

export default Home;
